import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SettingsService } from '../settings/settings.service';
import { Router } from '@angular/router';
import { TokenService } from '../token/token.service';

interface LoginResponse {
  token: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedInUser: Promise<any>;

  constructor(
    private http: HttpClient,
    private settings: SettingsService,
    private router: Router,
    private token: TokenService
  ) { }

  login(data): Promise<any> {
    const url = `${this.settings.API_BASE_URL}/auth/login`;
    return this.http.post<LoginResponse>(url, data).toPromise();
  }

  getLoggedInUser(refresh = false): Promise<any> {
    if (!this.loggedInUser || refresh) {
      const { userId } = this.token.getTokenData() || {};
      const url = `${this.settings.API_BASE_URL}/staff/${userId}`;
      return this.http.get(url).toPromise();
    }

    return this.loggedInUser;
  }

  isLoggedIn(): boolean {
    return this.token.isTokenExist();
  }

  logout() {
    this.loggedInUser = undefined;
    this.token.removeToken();
    this.router.navigate(['/login']);
  }

}
