import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private tokenKey = 'AUTH_TOKEN';

  constructor() { }

  saveToken(data, rememberMe) {
    /**
     * clear any session tokens before saving the new token -
     * to ensure that there will not be any old session ids stored in localstorage will not be there
     */
    this.removeToken();

    if (rememberMe) {
      window.localStorage.setItem(this.tokenKey, data);
      return;
    }

    window.sessionStorage.setItem(this.tokenKey, data);
  }

  getToken() {
    return window.sessionStorage.getItem(this.tokenKey) || window.localStorage.getItem(this.tokenKey);
  }


  getTokenData(): any {
    const token = this.getToken();
    if (!token) {
      return null;
    }
    return JSON.parse(window.atob(token.split('.')[1]));

  }

  isTokenExist() {
    return this.getToken() ? true : false;
  }

  removeToken() {
    window.localStorage.removeItem(this.tokenKey);
    window.sessionStorage.removeItem(this.tokenKey);
  }
}
