import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(
    private auth: AuthService,
    private router: Router,
  ) {

  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): boolean {

    const isLoggedIn = this.auth.isLoggedIn();
    const isGoingToLoginPage = route.path === 'login';
    if (!isLoggedIn && isGoingToLoginPage) {
      return true;
    }

    if (isLoggedIn && !isGoingToLoginPage) {
      return true;
    }

    if (isLoggedIn && isGoingToLoginPage) {
      this.router.navigate(['/']);
      return false;
    }

    this.router.navigate(['/login']);
    return false;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const isLoggedIn = this.auth.isLoggedIn();
    const isGoingToLoginPage = route.url.some(data => data.path.includes('login'));
    if (!isLoggedIn && isGoingToLoginPage) {
      return true;
    }

    if (isLoggedIn && !isGoingToLoginPage) {
      return true;
    }

    if (isLoggedIn && isGoingToLoginPage) {
      this.router.navigate(['/']);
      return false;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
