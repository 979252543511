import { Component, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.css']
})
export class TopnavComponent implements OnInit {
  isLoggedIn;
  @Input() snav;
  title = '';
  user: any;

  constructor(
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.auth.isLoggedIn();
    // console.log(this.isLoggedIn);
    if (this.isLoggedIn) {
      this.loadLoggedInUser();
    }

  }

  private async loadLoggedInUser(): Promise<void> {
    try {
      this.user = await this.auth.getLoggedInUser();
    } catch (error) {
      console.error(error);
    }
  }

  toggleSideNav() {
    this.snav?.toggle();
  }


  openChangePasswordModal() {
    // this.dialog.open(UpdatePasswordComponent);
  }


  showLoginIcon() {
    this.isLoggedIn = this.auth.isLoggedIn();
  }

  logout() {
    this.auth.logout();
    // console.log('Clicked On Logout');
  }

}
